<template>
  <div>
    <McdHeaderComponent v-if="showHeader" />
    <McdFooterComponent v-else />
    <!--    <button class="bi bi-plus icon-plus"></button>-->
  </div>
</template>

<script>
import McdHeaderComponent from "@/components/McdHeaderComponent";
import McdFooterComponent from "@/components/McdFooterComponent";

export default {
  name: "App",
  components: {
    McdHeaderComponent,
    McdFooterComponent,
  },
  data() {
    return {
      showHeader: false, // true = Header, false = Footer
    };
  },
};
</script>
<style></style>
