import { render, staticRenderFns } from "./DesktopNav.vue?vue&type=template&id=5bade285&scoped=true"
import script from "./DesktopNav.vue?vue&type=script&lang=js"
export * from "./DesktopNav.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./DesktopNav.vue?vue&type=style&index=0&id=5bade285&prod&lang=less&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5bade285",
  null
  ,true
)

export default component.exports