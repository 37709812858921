<template>
  <footer class="globalXfEnabler">
    <div id="footer">
      <div class="root container responsivegrid">
        <div id="container-54086cb47c" class="cmp-container">
          <div class="aem-Grid aem-Grid--12 aem-Grid--default--12">
            <div class="footer aem-GridColumn aem-GridColumn--default--12">
              <div class="cmp-footer" data-component="footer">
                <div class="cmp-footer__container">
                  <DesktopNav
                    class="footerNav"
                    :navigationRequestData="navigationItems"
                  />
                  <MobileNav
                    class="mobileNav"
                    :navigationRequestData="navigationItems"
                  />

                  <!--       ******* SocialNav *******         -->
                  <div
                    aria-label="social media and legal info"
                    role="navigation"
                  >
                    <div class="cmp-footer__promotion">
                      <div class="cmp-footer__social">
                        <div
                          class="image"
                          v-for="(social, i) in navigationItems.socialNavTop"
                          :key="i"
                        >
                          <div :data-title="social.alt" class="cmp-image">
                            <a
                              class="cmp-image__link external"
                              :href="social.url"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                :src="social.image"
                                class="cmp-image__image"
                                :alt="social.alt"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="cmp-footer__apps">
                        <div
                          v-for="(app, idx) in navigationItems.appNav"
                          :key="idx"
                        >
                          <div :data-title="app.alt" class="cmp-image">
                            <a
                              class="cmp-image__link external"
                              :href="app.url"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                :src="app.image"
                                class="cmp-image__image"
                                :alt="app.alt"
                                :id="app.id"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="separator">
                      <div>
                        <div id="separator-b8b5770d7e" class="cmp-separator">
                          <hr class="cmp-separator__horizontal-rule" />
                        </div>
                      </div>
                    </div>
                    <div class="cmp-footer__legal">
                      <p class="cmp__gma-redirect-country"></p>
                      <div class="cmp-footer__legal-links">
                        <div class="list">
                          <ul id="legallinks-1044746838" class="cmp-list">
                            <li
                              class="cmp-list__item"
                              v-for="(
                                link, index
                              ) in navigationItems.footerSecNav"
                              :key="index"
                            >
                              <a
                                class="cmp-list__item-link"
                                data-cmp-clickable=""
                                :href="link.url"
                              >
                                <span class="cmp-list__item-title">{{
                                  link.label
                                }}</span>
                              </a>
                              <span class="cmp-list__item-description"></span>
                            </li>
                            <li class="cmp-list__item">
                              <a
                                class="cmp-list__item-link"
                                href="#"
                                onclick="OneTrust.ToggleInfoDisplay(); return false;"
                                >Privatsphäre Einstellungen</a
                              >
                              <span class="cmp-list__item-description"></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="cmp-footer__copyright">
                        <div>
                          <div
                            data-cmp-lazythreshold="0"
                            data-asset-id="bcfc1c7c-275d-4a64-8835-136053de85bc"
                            data-title="Logo"
                            class="cmp-image"
                            itemscope=""
                          >
                            <img
                              loading="lazy"
                              src="https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/images/McDonald_Logo_on_white_desktop.jpg"
                              class="cmp-image__image"
                              itemprop="contentUrl"
                              data-cmp-hook-image="image"
                              alt="Logo"
                            />
                          </div>
                        </div>
                        <div class="text">
                          <div
                            data-component="text"
                            id="text-d8ead09bbf"
                            class="cmp-text"
                          >
                            <p>
                              ©{{ currentYear }} McDonald’s. Alle Rechte
                              vorbehalten.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import DesktopNav from "@/components/footer/DesktopNav";
import MobileNav from "@/components/footer/MobileNav";
export default {
  name: "McdFooterComponent",
  props: {
    msg: String,
  },
  components: {
    DesktopNav,
    MobileNav,
  },
  data() {
    return {
      navigationItems: {},
      showNavigationWhenDataLoaded: false,
      currentYear: "", // Initialize currentYear as an empty string
    };
  },
  created() {
    axios
      // .get("/navigation.json")
      .get(
        "https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/json/navigation.json"
      )
      .then((response) => {
        this.navigationItems = response.data;
        this.showNavigationWhenDataLoaded = true;
      });
    this.getCurrentYear(); // Call the function to get the current year when the component is created
  },
  methods: {
    getCurrentYear() {
      this.currentYear = new Date().getFullYear().toString(); // Set the current year as a string
    },
  },
  mounted() {
    const css = `
       @import url("https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/iconfont/bs-icons.css");

      @font-face {
        font-family: "Speedee";
        src: url('https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/fonts/Speedee_W_Lt.woff2') format('woff2');
        font-weight: 300;
        font-style: normal;
      }

      @font-face {
          font-family: "Speedee";
          src: url('https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/fonts/Speedee_W_Rg.woff2') format('woff2');
          src: url('https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/fonts/Speedee_W_Rg.ttf') format('truetype');;
          font-weight: 400;
          font-style: normal;
        }

      @font-face {
          font-family: "Speedee";
          src: url('https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/fonts/Speedee_W_Bd.woff2') format('woff2');
          src: url('https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/fonts/Speedee_W_Bd.ttf') format('truetype');
          font-weight: 700;
          font-style: normal;
        }`;

    // if our style is already injected we do not need to inject it a second time
    if (!document.getElementById("myCustomInjectedStyle")) {
      const head = document.head || document.getElementsByTagName("head")[0];
      const style = document.createElement("style");
      style.id = "myCustomInjectedStyle";
      style.type = "text/css";
      style.innerText = css;
      head.appendChild(style);
    }
  },
};
</script>

<style>
@import url("https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/iconfont/bs-icons.css");

[type="button"],
[type="reset"],
[type="submit"],
button {
  cursor: pointer;
}

footer {
  background-color: #fff;
  > * {
    margin: 0;
    font-family: Speedee, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica Neue, Arial, Noto Sans, sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: #292929;
    letter-spacing: -0.15px;

    @media (min-width: 1024px) {
      font-size: 1pc;
    }
  }
}

.cmp-title h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
}

.cmp-title h2 {
  font-weight: 700;
}

.cmp-title h2,
.cmp-title h3 {
  font-size: 28px;
  line-height: 2pc;
}

.cmp-title h3 {
  font-weight: 400;
}

.cmp-title h4 {
  font-weight: 700;
}

.cmp-title h4,
.cmp-title h5 {
  font-size: 20px;
  line-height: 24px;
}

.cmp-title h5 {
  font-weight: 400;
}

.cmp-title h6 {
  font-size: 1pc;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 1024px) {
  .cmp-title h1 {
    font-size: 54px;
    font-weight: 700;
    line-height: 56px;
  }

  .cmp-title h2 {
    font-weight: 700;
  }

  .cmp-title h2,
  .cmp-title h3 {
    font-size: 36px;
    line-height: 40px;
  }

  .cmp-title h3 {
    font-weight: 400;
  }

  .cmp-title h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 2pc;
  }

  .cmp-title h5 {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
  }

  .cmp-title h6 {
    font-size: 1pc;
    font-weight: 700;
    line-height: 24px;
  }
}

.cmp-title__text {
  margin: 0 0 13px;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 30px;
}

@media (min-width: 1024px) {
  .cmp-title__text {
    margin: 0 0 17px;
    font-size: 40px;
    line-height: 46px;
  }
}

.cmp-footer__nav-section {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66%;
  flex: 0 0 16.66%;
}

.cmp-footer__nav-sections {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cmp-footer__nav-section .cmp-title h2 {
  line-height: 20px;
  font-size: 1pc;
  margin-bottom: 20px;
  margin-top: revert;
  text-align: left;
}

.cmp-footer__nav-heading {
  font-size: 1pc;
  font-weight: 400;
  margin-bottom: 20px;
}

.cmp-footer__nav-heading,
.cmp-footer__nav-heading a {
  color: #292929;
  line-height: 20px;
}

.cmp-footer__nav-links,
.cmp-footer__nav-links a {
  color: #292929;
}

.cmp-footer__nav-links .cmp-list {
  line-height: 22px;
}

.cmp-footer__nav-links .cmp-list__item {
  margin-bottom: 6px;
  cursor: pointer;
}

.cmp-footer__nav-links .cmp-list__item-title {
  line-height: 22px;
}

.cmp-footer .cmp-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cmp-footer__container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 36px 20px;
  background-color: #fff;
}

@media (min-width: 1024px) {
  .cmp-footer__container {
    padding-top: 75pt;
    padding-bottom: 75pt;
  }
}

.cmp-footer img {
  vertical-align: middle;
}

.cmp-footer a {
  text-decoration: none;
}

.cmp-footer__promotion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 39px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .cmp-footer__promotion {
    padding-bottom: 46px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.cmp-footer .separator hr {
  border-bottom: none;
  border-top: 1px solid #cecece;
  margin: 0 -20px 20px;
}

@media (min-width: 1024px) {
  .cmp-footer .separator hr {
    margin-bottom: 50px;
  }
}

.cmp-footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cmp-footer__social img {
  max-width: 2pc;
  max-height: 2pc;
}

@media (min-width: 1024px) {
  .cmp-footer__social {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 66%;
    flex: 0 1 66%;
    margin-bottom: 0;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .cmp-footer__social > * {
    margin-right: 21px !important;
    margin-bottom: 0 !important;
  }

  .cmp-footer__social > :last-child {
    margin-right: 0 !important;
  }
}

.cmp-footer__social > * {
  margin-right: 17px;
  margin-bottom: 10px;
}

.cmp-footer__apps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.cmp-footer__apps > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 120px;
  flex: 0 0 120px;
}

@media (min-width: 1024px) {
  .cmp-footer__apps > * {
    margin: 0 5px;
  }
}

@media (min-width: 1024px) {
  .cmp-footer__apps {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 34%;
    flex: 0 1 34%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.cmp-footer__apps img {
  width: auto;
  max-width: 155px;
}

@media (min-width: 1024px) {
  .cmp-footer__apps img:first-child {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
}

.cmp-footer__legal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 9pt;
  line-height: 14px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .cmp-footer__legal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    font-size: 1pc;
    line-height: 24px;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
  }
}

.cmp-footer__legal-links {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

@media (min-width: 1024px) {
  .cmp-footer__legal-links {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66%;
    flex: 0 0 66.66%;
  }
}

.cmp-footer__legal-links .cmp-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .cmp-footer__legal-links .cmp-list {
    -webkit-box-pack: normal;
    -ms-flex-pack: normal;
    justify-content: normal;
  }
}

.cmp-footer__legal-links .cmp-list__item {
  margin-bottom: 18px;
  margin-right: 10px;
}

.cmp-footer__legal-links .cmp-list__item:first-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 0;
}

@media (min-width: 1024px) {
  .cmp-footer__legal-links .cmp-list__item:first-of-type {
    display: list-item;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    -webkit-box-pack: normal;
    -ms-flex-pack: normal;
    justify-content: normal;
    margin-right: 40px;
  }
}

@media (min-width: 1024px) {
  .cmp-footer__legal-links .cmp-list__item {
    margin-right: 40px;
  }
}

.cmp-footer__legal-links .cmp-list__item a {
  color: #292929;
  cursor: pointer;
}

.cmp-footer__legal-links .cmp-list .cmp-footer__cookie-setting {
  display: block;
  padding: 0;
}

.cmp-footer__legal-links
  .cmp-list
  .cmp-footer__cookie-setting
  .cookiesettings
  .cmp-text
  > p {
  margin: 0;
}

.cmp-footer__copyright {
  line-height: 1pc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  padding: 0 20px;
}

.cmp-footer__copyright > * {
  display: inline-block;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

@media (min-width: 1024px) {
  .cmp-footer__copyright {
    -webkit-box-pack: normal;
    -ms-flex-pack: normal;
    justify-content: normal;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 33.333%;
    flex: 0 1 33.333%;
    padding: 0;
  }
}

.cmp-footer__copyright img {
  max-height: 28px;
  -o-object-fit: contain;
  object-fit: contain;
  margin-right: 4px;
  width: auto;
}

@media (min-width: 1024px) {
  .cmp-footer__copyright img {
    margin-left: 36px;
  }
}

.cmp-footer__copyright > .text {
  text-align: center;
}

@media (min-width: 1024px) {
  .cmp-footer__copyright > .text {
    text-align: right;
  }
}

.cmp-footer__copyright > .text p {
  margin: 0;
}

.cmp-footer__cookie-setting .optanon-show-settings-left {
  display: none;
}

.cmp-footer__cookie-setting .optanon-show-settings-middle {
  border: none !important;
  padding: 0 !important;
}

.cmp-footer__cookie-setting .cmp-text {
  color: #292929;
  font-family: Speedee, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif;
}

@media (min-width: 1024px) {
  .cmp-footer__cookie-setting .cmp-text {
    padding-top: 5px;
  }
}

.cmp-footer__cookie-setting .cmp-text p a {
  font-size: 9pt;
  font-family: Speedee, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif;
  line-height: 2;
  margin: 0;
}

@media (min-width: 1024px) {
  .cmp-footer__cookie-setting .cmp-text p a {
    line-height: 1;
    font-size: 1pc;
  }
}

.cmp-footer__cookie-setting .optanon-show-settings-right {
  display: none;
}

.cmp-footer--author-mode .cmp-footer__apps {
  display: block;
}

.cmp-footer--author-mode .cmp-footer__apps > * {
  max-width: 90pt;
  margin: 0 auto;
}

.cmp-footer--author-mode .cmp-footer__legal-links {
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
}

.cmp-footer--author-mode .cmp-footer__copyright {
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
}

.cmp-footer--author-mode .cmp-footer__copyright .cmp-text {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
}

.cmp-footer--author-mode .cmp-footer__copyright > :first-child {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
}

.overlay-footer .cmp-footer__container .separator {
  overflow: hidden;
}

@media (min-width: 1024px) {
  .overlay-footer .cmp-footer__container .separator {
    overflow: visible;
  }
}

@media (min-width: 1024px) {
  .overlay-footer {
    opacity: 0.1;
    pointer-events: none;
  }
}

.cmp__gma-redirect-country {
  display: none;
  margin: 25px 0;
  font-size: 11px;
  width: 100%;
  text-align: center;
}

@media (min-width: 1024px) {
  .cmp__gma-redirect-country {
    margin: 4px 40px 0 0;
    font-size: 14px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-weight: 700;
    line-height: 1pc;
    white-space: nowrap;
  }
}

.gmaredirect .cmp-footer__legal .cmp__gma-redirect-country {
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

@media (min-width: 1024px) {
  .gmaredirect .cmp-footer__legal .cmp__gma-redirect-country {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 7%;
    flex: 0 0 7%;
  }
}

.gmaredirect .cmp-footer__legal .cmp-footer__legal-links {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

@media (min-width: 1024px) {
  .gmaredirect .cmp-footer__legal .cmp-footer__legal-links {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
  }
}

.gmaredirect .cmp-footer__legal .cmp-footer__copyright {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

@media (min-width: 1024px) {
  .gmaredirect .cmp-footer__legal .cmp-footer__copyright {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 33%;
    flex: 0 1 33%;
  }
}

.gmaredirect .cmp-footer__legal .cmp-footer__copyright .cmp-image {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

@media (min-width: 1024px) {
  .gmaredirect .cmp-footer__legal .cmp-footer__copyright .text .cmp-text p {
    white-space: nowrap;
  }
}

.gmaredirect p.desktop,
.gmaredirect p.mobile {
  margin-bottom: 15px;
}

.gmaredirect
  .cmp-list__item
  .cmp-footer__cookie-setting
  .optanon-show-settings-middle {
  height: auto !important;
}

.gmaredirect
  .cmp-list__item
  .cmp-footer__cookie-setting
  .cookiesettings.text.optanon-show-settings {
  padding: 0 !important;
}

.gmaredirect .cmp-list__item .cmp-footer__cookie-setting .cmp-text p a {
  font-size: 11px;
}

@media (min-width: 1024px) {
  .gmaredirect .cmp-list__item .cmp-footer__cookie-setting .cmp-text p a {
    font-size: 14px;
  }
}
</style>
