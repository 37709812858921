<template>
  <nav class="cmp-footer__nav-mobile" aria-label="McDonald's footer links">
    <div class="accordion cmp-accordion">
      <div id="accordion-6c8513b5eb" class="cmp-accordion">
        <div
          class="cmp-accordion__item accordion"
          v-for="(accordionItem, index) in navigationRequestData.footerMainNav"
          :key="index"
        >
          <div
            class="accordion-menu-header"
            @click="toggleAccordion(index)"
            role="tab"
          >
            <h2 class="cmp-accordion__header">
              <button
                id="accordion-mobile-one-item-ac52ebd13f-button"
                class="cmp-accordion__button"
                aria-controls="accordion-mobile-one-item-ac52ebd13f-panel"
                data-cmp-hook-accordion="button"
                aria-expanded="false"
              >
                <span class="cmp-accordion__title" data-cmp-clickable="">
                  {{ accordionItem.title }}</span
                >
                <span
                  class="bi bi-plus icon-plus"
                  :class="{ open: isOpen(index) }"
                ></span>
              </button>
            </h2>
          </div>
          <div
            v-if="isOpen(index)"
            class="accordion-menu-items"
            role="tabpanel"
          >
            <div
              data-cmp-hook-accordion="panel"
              id="accordion-mobile-one-item-ac52ebd13f-panel"
              class="cmp-accordion__panel cmp-accordion__panel--hidden"
              role="region"
              aria-labelledby="accordion-mobile-one-item-ac52ebd13f-button"
              aria-hidden="true"
            >
              <div class="list">
                <ul class="cmp-list">
                  <li
                    class="cmp-list__item"
                    v-for="(listItem, idx) in accordionItem.list"
                    :key="idx"
                  >
                    <a
                      class="cmp-list__item-link"
                      data-cmp-clickable=""
                      :href="listItem.url"
                    >
                      <span class="cmp-list__item-title">{{
                        listItem.title
                      }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "MobileNav",
  props: ["navigationRequestData"],
  components: {},
  data() {
    return {
      items: [
        { title: "Section 1", content: "Content 1" },
        { title: "Section 2", content: "Content 2" },
        { title: "Section 3", content: "Content 3" },
      ],
      openIndex: null,
    };
  },

  methods: {
    getCollapseId(index) {
      return "collapse-" + index;
    },
    toggleAccordion(index) {
      if (this.openIndex === index) {
        this.openIndex = null; // Close if already open
      } else {
        this.openIndex = index; // Open
      }
    },
    isOpen(index) {
      return this.openIndex === index;
    },
  },
};
</script>
<style>
footer {
  .icon-plus {
    font-size: 30px;
    height: 22px;
  }

  .open.icon-plus:before {
    content: "\f2ca";
  }
}

.cmp-footer__nav-mobile {
  margin-bottom: 27px;
}

.cmp-footer__nav-mobile a {
  color: #292929;
}

.cmp-footer__nav-mobile .cmp-accordion__header {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
}

.cmp-footer__nav-mobile .cmp-accordion__button {
  border: 0;
  width: 100%;
  text-align: left;
  background: none;
  padding: 0;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 400;
  line-height: 22px;
  color: #292929;
}

.cmp-footer__nav-mobile .cmp-accordion__button .cmp-accordion__icon {
  font-family: icomoon;
  font-size: 1pc;
}

.cmp-footer__nav-mobile .cmp-accordion__button .cmp-accordion__icon:before {
  content: "";
}

.cmp-footer__nav-mobile .cmp-accordion__button--expanded .cmp-accordion__icon {
  font-family: icomoon;
  font-size: 2px;
}

.cmp-footer__nav-mobile
  .cmp-accordion__button--expanded
  .cmp-accordion__icon:before {
  content: "";
}

.cmp-footer__nav-mobile .cmp-accordion__panel--expanded {
  padding-bottom: 20px;
}

.cmp-footer__nav-mobile .cmp-accordion__panel .cmp-list__item:not(:last-child) {
  margin-bottom: 17px;
}

@media (min-width: 1024px) {
  .cmp-footer__nav-mobile {
    display: none;
  }
}
</style>
