<template>
  <nav class="cmp-footer__nav-desktop" aria-label="McDonald's footer links">
    <ul class="cmp-footer__nav-sections">
      <li
        class="cmp-footer__nav-section"
        v-for="section in navigationRequestData.footerMainNav"
        :key="section.title"
      >
        <div class="cmp-title">
          <h2 class="cmp-title__text">{{ section.title }}</h2>
        </div>
        <div class="cmp-footer__nav-links">
          <div class="list">
            <ul class="cmp-list">
              <li
                class="cmp-list__item"
                v-for="item in section.list"
                :key="item.title"
              >
                <a class="cmp-list__item-link" :href="item.url">
                  <span class="cmp-list__item-title">{{ item.title }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import Vue from "vue";
import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);
export default {
  name: "DesktopNav",
  props: ["navigationRequestData"],
  data() {
    return {
      showDropdown: false,
    };
  },
  mounted() {},
};
</script>
<style>
.cmp-footer__nav-desktop {
  background-color: #fff;
  margin-bottom: 40px;
}
.cmp-footer__nav-desktop {
  display: none;
}
@media (min-width: 1024px) {
  .cmp-footer__nav-desktop {
    display: block;
  }
}
</style>
